import React from 'react';
import { Route } from 'react-router-dom';
import LoginPage from '../pages/login-page';
import SignupPage from '../pages/signup-page';


const AuthRoutes = () => {
  return (
    <>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path='/signup' component={SignupPage} />
    </>
  );
};

export default AuthRoutes;