import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import pyyneLogo from '../../assets/images/logo-high-res-tree-only.png'
import { CurrentUserContext } from '../../utils/contexts/current-user.context'

function LoginForm () {
  const [loginParams, setLoginInfo] = useState({ email: '', password: '' })
  const history = useHistory()
  const AuthContext = useContext(CurrentUserContext)

  const login = async (loginParams) => {
    const { success } = await AuthContext.login(loginParams)
    if (success) {
        history.push('/consultants')
      return
    }
  }

  const submitHandler = e => {
    e.preventDefault()
    login(loginParams)
  }

  return (
    <div className='login-signup-form-container'>
      <form onSubmit={submitHandler}>
        <img src={pyyneLogo} alt='PYYNE Logo' />
        <div className='form-input-container'>
          <input type='email' name='email' placeholder='Email' id='email' onChange={e => setLoginInfo({ ...loginParams, email: e.target.value })} value={loginParams.email} />
        </div>
        <div className='form-input-container'>
          <input type='password' name='password' placeholder='Password' id='password' onChange={e => setLoginInfo({ ...loginParams, password: e.target.value })} value={loginParams.password} />
        </div>
        <div className='form-submit-container'>
          <input className='submit-button' type='submit' value='LOGIN' />
        </div>
        <div className=''>Don't have an account? <Link to='/signup'>Sign up</Link></div>
      </form>
    </div>
  )
}

export default LoginForm
