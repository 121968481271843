import toast from 'react-hot-toast'
import { getAxiosInstance } from './axios'

const ConsultantService = {
  list: async (token, listParams) => {
    try{
        const response = await getAxiosInstance(token).get('/consultant', {
            params:{...listParams}
        })
        return { status: true, consultants: response.data }
    }catch(error){
        let errorMessage
        if(error.response && error.response.data){
            errorMessage = error.response.data.error
        }else{
            errorMessage = 'Connection failed'
        }
        toast.error(errorMessage)
        return {
          status: false,
          message: errorMessage,
          consultants: []
        }
    }
  },

  create: async (token, createParams) => {
    try{
        const response = await getAxiosInstance(token).post('/consultant', createParams)
        return { status: true, consultant: response.data }
    }catch(error){
        let errorMessage
        if(error.response && error.response.data){
            errorMessage = error.response.data.error
        }else{
            errorMessage = 'Connection failed'
        }
        toast.error(errorMessage)
        return {
          status: false,
          message: errorMessage,
          consultant: null
        }
    }
  },

  update: async (token, updateParams) => {
    try{
        const response = await getAxiosInstance(token).put(`/consultant/${updateParams.id}`, updateParams)
        return { status: true, consultant: response.data }
    }catch(error){
        let errorMessage
        if(error.response && error.response.data){
            errorMessage = error.response.data.error
        }else{
            errorMessage = 'Connection failed'
        }
        toast.error(errorMessage)
        return {
          status: false,
          message: errorMessage,
          consultant: null
        }
    }
  },

  delete: async (token, id) => {
    try{
        await getAxiosInstance(token).delete(`/consultant/${id}`)
        return { status: true, message: 'Consultant successfully deleted' }
    }catch(error){
        let errorMessage
        if(error.response && error.response.data){
            errorMessage = error.response.data.error
        }else{
            errorMessage = 'Connection failed'
        }
        toast.error(errorMessage)
        return {
          status: false,
          message: errorMessage
        }
    }
  }
}

export default ConsultantService
