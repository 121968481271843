import React from 'react'
import * as AiIcons from 'react-icons/ai'

export const SidebarData = [
  {
    title: 'Consultants',
    path: '/consultants',
    icon: <AiIcons.AiOutlineProject />,
    cName: 'nav-text'
  }
]
