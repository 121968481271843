

import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { CurrentUserContext } from '../../utils/contexts/current-user.context';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { getCurrentUser } = useContext(CurrentUserContext);

    const isAuthenticated = getCurrentUser()
    return (
        <Route
            {...rest}
            render={props=>
                isAuthenticated ?
                <Component {...props } /> :
                <Redirect to={{pathname: '/login', state: { from: props.location }}} />
            }
        />
    )
}

export default PrivateRoute