import React from 'react';
import { Toaster } from 'react-hot-toast';
import './assets/styles/general/app.styles.scss';
import Routes from './routes/routes';
import { CurrentUserProvider } from './utils/contexts/current-user.context';


function App () {
  return (
    <div className='App'>
        <Toaster />
      <CurrentUserProvider>
        <Routes />
      </CurrentUserProvider>
    </div>
  )
}

export default App
