import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import Navbar from '../components/general/navbar.component';
import { CurrentUserContext } from '../utils/contexts/current-user.context';
import AuthRoutes from './auth-routes';
import MainRoutes from './main-routes';


const Routes = () => {
 const { getCurrentUser } = useContext(CurrentUserContext);
 const isAuthenticated = getCurrentUser()

 return(
    <>
      {isAuthenticated ? <Navbar /> : ''}
      <BrowserRouter>
        <Route path="/">
          {isAuthenticated ? <Redirect to="/consultants" /> : <Redirect to="/login" />}
        </Route>
        <AuthRoutes />
        <MainRoutes />
      </BrowserRouter>
    </>
 )
};

export default Routes;