import React from 'react'
import '../../assets/styles/consultants-finder-page/consultant-card.styles.scss'
import DateHelper, { dateFormats } from '../../utils/helpers/date.helper'


function ConsultantCardInfo({ consultant }) {

  const consultantAvailabilities = {
    'F': 'Free',
    'PT': 'Part-time',
    'FT': 'Full-time'
  }

  const consultantLevels = {
    'A': 'Analyst',
    'AC': 'Associate Consultant',
    'C': 'Consultant',
    'SRC': 'Senior Consultant',
    'AM': 'Associate Manager',
    'M': 'Manager',
    'SRM': 'Senior Manager',
    'PE': 'Principal Engineer',
    'P': 'Partner'
  }

  const consultantLocations = {
    'NY': 'New York',
    'SP': 'São Paulo',
    'SK': 'Stockholm'
  }

  return (
    <>
      <div className='card-consultant-info-container'>

        <div className='info-header'>
          <div className='consultant-title'>{consultant.name}</div>
          <input type='color' className='color-input info' value={consultant.color} disabled />
        </div>

        <div className='consultant-info-columns'>
          <div className='consultant-info left-column'>
            <div className='consultant-attribute'><label>Level:</label>{consultantLevels[consultant.level]}</div>
            <div className='consultant-attribute'><label>Location:</label>{consultantLocations[consultant.location]}</div>
          </div>
          <div className='consultant-info right-column'>
            <div className='consultant-attribute'>
              <label>Availability:</label>
              <span>{consultantAvailabilities[consultant.availability]}
                {consultant.availabilityDate
                  ? (
                    <>
                      {
                        consultant.availability === 'F'
                          ? (' since ')
                          : (' until ')
                      }
                      {DateHelper.timestampToDateFormat(consultant.availabilityDate, dateFormats.SLASH_FORMAT)}
                    </>
                  )
                  : ('')}
              </span>
            </div>
          </div>
        </div>

        <div className='consultant-info-columns other-columns'>
          <div className='consultant-info left-column consultant-skills'>
            Skills: <span>{consultant.skills}</span>
          </div>
          <div className='consultant-info right-column consultant-comments'>
            Comments: <span>{consultant.comments}</span>
          </div>
        </div>
      </div>

    </>

  )
}

export default ConsultantCardInfo
