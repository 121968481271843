import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

function CreateConsultantModal({open, handleClose, createParams, setCreateParams, onSubmit}){

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4
      }

    return(
        <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography id='modal-title' className='modal-tile' variant='h6' component='h2'>
              <b>Create consultant</b>
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2 }} component='div'>
              <div className='custom-modal-container'>
                <div className='modal-input-container'>
                  <input type='text' value={createParams.name} placeholder='Name' onChange={e => setCreateParams({ ...createParams, name: e.target.value })}/>
                </div>
                <div className='modal-input-container'>
                  <select value={createParams.level} onChange={(e) => setCreateParams({ ...createParams, level: e.target.value })}>
                    <option value='' select='selected' disabled>Choose level</option>
                    <option value='A'>Analyst</option>
                    <option value='AC'>Associate Consultant</option>
                    <option value='C'>Consultant</option>
                    <option value='SRC'>Senior Consultant</option>
                    <option value='AM'>Associate Manager</option>
                    <option value='M'>Manager</option>
                    <option value='SRM'>Senior Manager</option>
                    <option value='PE'>Principal Engineer</option>
                    <option value='P'>Partner</option>
                  </select>
                </div>
                <div className='modal-input-container'>
                  <select value={createParams.location} onChange={(e) => setCreateParams({ ...createParams, location: e.target.value })}>
                    <option value='' select='selected' disabled>Select location</option>
                    <option value='NY'>New York</option>
                    <option value='SP'>São Paulo</option>
                    <option value='SK'>Stockholm</option>
                  </select>
                </div>
                <div className='modal-bottom'>
                  <button onClick={onSubmit}>Create</button>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    )
}

export default CreateConsultantModal