import React from 'react';
import PrivateRoute from '../components/general/private-route.component';
import ConsultantsFinderPage from '../pages/consultants-finder-page';


const MainRoutes = () => {
  return (
      <>
        <PrivateRoute exact path="/consultants" component={ConsultantsFinderPage} />
      </>
  );
};

export default MainRoutes;