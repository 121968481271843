import React from 'react'
import '../../assets/styles/consultants-finder-page/availability-filter.styles.scss'
import '../../assets/styles/consultants-finder-page/filter.styles.scss'


function AvailabilityFilter ({keyword, setKeyword}) {
  return (
    <div className='filter-container availability-container'>
      <div className='filter-title'>Available since</div>
      <input
      id="date"
      type="date"
        value={keyword}
        className='date-filter'
        onChange={(e) => setKeyword(e.target.value)}
      />
    </div>
  )
}

export default AvailabilityFilter
