import React from 'react'
import '../assets/styles/login-signup/login-signup-pages.styles.scss'
import SignupForm from '../components/login-signup-pages/signup-form.component'


function SignupPage () {
  return (
    <div className='login-signup-content-container'>
      <SignupForm />
    </div>
  )
}

export default SignupPage
