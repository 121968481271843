import React from 'react'
import '../assets/styles/login-signup/login-signup-pages.styles.scss'
import LoginForm from '../components/login-signup-pages/login-form.component'


function LoginPage () {
  return (
    <div className='login-signup-content-container'>
      <LoginForm />
    </div>
  )
}

export default LoginPage
