import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import '../assets/styles/consultants-finder-page/consultants-page.styles.scss'
import '../assets/styles/general/custom-modal.styles.scss'
import AvailabilityFilter from '../components/consultants-finder-page/availability-filter.component'
import ConsultantCard from '../components/consultants-finder-page/consultant-card.component'
import CreateConsultantModal from '../components/consultants-finder-page/create-consultant-modal.component'
import LevelsFilter from '../components/consultants-finder-page/levels-filter.component'
import LocationFilter from '../components/consultants-finder-page/location-filter.component'
import SearchBar from '../components/consultants-finder-page/search-bar.component'
import { CurrentUserContext } from '../utils/contexts/current-user.context'
import DateHelper from '../utils/helpers/date.helper'
import ConsultantService from '../utils/services/consultant-service'


function ConsultantsFinderPage() {

  const { getCurrentUser } = useContext(CurrentUserContext)
  // Modal variables
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)


  const [filteredConsultants, setFilteredConsultants] = useState([])
  const [createConsultantParams, setCreateConsultantParams] = useState({
    'name': '',
    'level': '',
    'location': ''
  })

  const [searchQuery, setSearchQuery] = useState('')
  const [offices, setOffices] = useState([])
  const [levels, setLevels] = useState([])
  const [availabilityDate, setAvailabilityDate] = useState('')

  useEffect(() => {
    getConsultants()
  }, [])


  useEffect(() => {
    getConsultants()
  }, [searchQuery, offices, levels, availabilityDate])


  async function getConsultants() {
    let queryParams = {
      searchQuery: searchQuery,
      locations: offices,
      levels: levels,
    }
    let timestamp = DateHelper.dateToEpochTimestamp(availabilityDate)
    if (!isNaN(timestamp)) {
      queryParams.availabilityDate = timestamp
    }
    let { status, consultants } = await ConsultantService.list(getCurrentUser().token, queryParams)
    if (status) {
      setFilteredConsultants(consultants)
    }
  }

  const createConsultant = async () => {

    if (!createConsultantParams.location) {
      toast.error('Location is a required field')
      return
    } else if (!createConsultantParams.level) {
      toast.error('Level is a required field')
      return
    } else if (!createConsultantParams.name) {
      toast.error('name is a required field')
      return
    } else {
      let { status } = await ConsultantService.create(getCurrentUser().token, createConsultantParams)
      if (status) {
        await getConsultants()
        setCreateConsultantParams({
          name: '',
          level: '',
          location: ''
        })
        handleClose()
      }
    }
  }

  const updateConsultant = async () => {
    await getConsultants()
  }

  const deleteConsultant = async () => {
    await getConsultants()
  }


  return (
    <div className='consultants-page-container'>

      <div className='page-header'>
        <button className='create-button' onClick={() => { handleOpen() }}>Create Consultant</button>
      </div>

      <div className='page-body'>
        <div className='cpage-left'>
          <h1>Filters</h1>
          <SearchBar keyword={searchQuery} setKeyword={setSearchQuery} />
          <LevelsFilter keyword={levels} setKeyword={setLevels} />
          <LocationFilter keyword={offices} setKeyword={setOffices} />
          <AvailabilityFilter keyword={availabilityDate} setKeyword={setAvailabilityDate} />
        </div>
        <div className='cpage-right'>
          {filteredConsultants && filteredConsultants.length
            ? (filteredConsultants.map((consultant) => {
              return (
                <ConsultantCard updateConsultant={updateConsultant} deleteConsultant={deleteConsultant} consultant={consultant} key={consultant.id} />
              )
            }))
            : 'No Consultants Found'
          }
        </div>
      </div>

      <CreateConsultantModal
        open={open}
        handleClose={handleClose}
        onSubmit={createConsultant}
        createParams={createConsultantParams}
        setCreateParams={setCreateConsultantParams}
      />
    </div>
  )
}

export default ConsultantsFinderPage
