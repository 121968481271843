import toast from 'react-hot-toast'
import { getAxiosInstance } from './axios'

const UserService = {
  login: async (loginParams) => {
    try {
      const response = await getAxiosInstance().post('/login', {
        email: loginParams.email,
        password: loginParams.password
      })
        return { success: true, message: 'Login Successfull', user: response.data }
    } catch (error) {

        let errorMessage
        if(error.response && error.response.data){
            errorMessage = error.response.data.error
        }else{
            errorMessage = 'Connection failed'
        }
      toast.error(errorMessage)
      return {
        status: false,
        message: errorMessage,
        user: null
      }
    }
  },

  logout: async (token) => {
      try {
        await getAxiosInstance(token).post('/logout')
        toast.success('Logout Success!')
        return { status: true, message: 'Logout Success' }
      } catch (error) {
        let errorMessage
        if(error.response && error.response.data){
            errorMessage = error.response.data.error
        }else{
          errorMessage = 'Connection failed'
        }
        return {
            status: false,
            message: errorMessage
        }
      }
  },

  signup: async (signupParams) => {
    try {
      await getAxiosInstance().post('/user', {
        email: signupParams.email,
        password: signupParams.password,
        name: signupParams.name,
        passwordConfirmation: signupParams.passwordConfirmation
      })
      toast.success('User Created!')
      return { status: true, message: 'User Created' }
    } catch (error) {
      let errorMessage
      if(error.response && error.response.data){
          errorMessage = error.response.data.error
      }else{
        errorMessage = 'Connection failed'
      }
      toast.error(errorMessage)
      return {
        status: false,
        message: errorMessage
      }
    }

  }
}

export default UserService
