import React from 'react';
import '../../assets/styles/consultants-finder-page/filter.styles.scss';

export default function LocationFilter ({ keyword, setKeyword }) {
  const availableLocations = ['NY', 'SP', 'SK']
  const handleSelection = (event) => {
    if(keyword.includes(event.target.value)){
        setKeyword([...keyword.filter((value) => {
            return value !== event.target.value
        })])
    }else{
        addToArray(event.target.value)
    }
}

  const addToArray = (value) => {
      setKeyword([...keyword, value])
  }
  return (
    <div className='filter-container'>
      <div className='filter-title'>Offices</div>
      <table className='filter-buttons'>
        <tbody>
          <tr>
              {availableLocations.map((value, index) => (
                  <td key={index} >
                        <button
                            value={value}
                            onClick={handleSelection}
                            className={keyword.includes(value) ? 'filter-button active' :'filter-buttons'}
                        >
                            {value}
                        </button>
                  </td>
              ))}
          </tr>
        </tbody>
      </table>
    </div>
  )
}
