import React, { useContext, useState } from 'react'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import '../../assets/styles/consultants-finder-page/consultant-card.styles.scss'
import { CurrentUserContext } from '../../utils/contexts/current-user.context'
import DateHelper from '../../utils/helpers/date.helper'
import ConsultantService from '../../utils/services/consultant-service'
import ConsultantCardInfoUpdate from './consultant-card-info-update.component'
import ConsultantCardInfo from './consultant-card-info.component'

function ConsultantCard (props) {
    const { getCurrentUser } = useContext(CurrentUserContext)

  const [consultant, setConsultant] = useState(props.consultant)
  const [consultantUpdateInfo, setConsultantUpdateInfo] = useState({...consultant, availabilityDate: DateHelper.timestampToDateFormat(consultant.availabilityDate)})
  const [editMode, setEditMode] = useState(false)

  const saveConsultant = async () => {
      if(editMode){
        try {

            Object.keys(consultantUpdateInfo).forEach(key => {
                if (consultantUpdateInfo[key] === null) {
                  delete consultantUpdateInfo[key];
                }
              });
            let timestamp = DateHelper.dateToEpochTimestamp(consultantUpdateInfo.availabilityDate)
            if(!isNaN(timestamp)){
                consultantUpdateInfo.availabilityDate = timestamp
            }else{
                toast.error('Consultant must have a availability date')
                return
            }
            let { status, consultant: updatedConsultant} = await ConsultantService.update(getCurrentUser().token, consultantUpdateInfo)
            if(status){
                setEditMode(!editMode)
                setConsultant(updatedConsultant)
                setConsultantUpdateInfo({...updatedConsultant, availabilityDate: DateHelper.timestampToDateFormat(updatedConsultant.availabilityDate)})
                props.updateConsultant()
            }
          } catch (error) {
              console.log(error)
          }
      }else{
        setEditMode(!editMode)
        setConsultant(consultant)
      }
  }

  const deleteConsultant = async () => {

    Swal.fire({
        title: 'Are you sure you want to delete this consultant?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                let { status } = await ConsultantService.delete(getCurrentUser().token, consultant.id)
                if(status){
                    setEditMode(!editMode)
                    props.deleteConsultant()
                }
              } catch (error) {
                  console.log(error)
              }
        }
      })

  }

  return (
    <div className='card-container'>
      {editMode
        ? (<ConsultantCardInfoUpdate consultantUpdateInfo={consultantUpdateInfo} setConsultantUpdateInfo={setConsultantUpdateInfo}>
            <div className='delete-action'>
                <button onClick={deleteConsultant}>Delete Consultant</button>
            </div>
            </ConsultantCardInfoUpdate>)
        : (<ConsultantCardInfo consultant={consultant} />)}
      <div className='card-actions-container'>
        <button className={`${editMode ? 'save-button' : ''}`} onClick={saveConsultant}>
          {editMode ? ('Save') : ('Edit')}
        </button>
      </div>
    </div>
  )
}

export default ConsultantCard
