import React, { useContext, useState } from 'react'
import { IconContext } from 'react-icons'
import * as AiIcons from 'react-icons/ai'
import * as FaIcons from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import '../../assets/styles/general/navbar.styles.scss'
import { CurrentUserContext } from '../../utils/contexts/current-user.context'
import { SidebarData } from '../../utils/data/sidebar-menu-data.data'


function Navbar () {

  const history = useHistory()
  const AuthContext = useContext(CurrentUserContext)
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)

  const logout = async () => {
    const { status } = await AuthContext.logout()
    if (status) {
      history.push('/login')
    }
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className='navbar'>
          <div className='nav-let'>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
          <div className='nav-right'>
            <div className='nav-pages'>
              <a href='/consultants'> Consultants</a>
            </div>
            <button className='logout-button' onClick={logout}>
              Logout
            </button>
          </div>
        </nav>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
