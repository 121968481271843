import React from 'react';
import '../../assets/styles/consultants-finder-page/search-bar.styles.scss';

const SearchBar = ({ keyword, setKeyword }) => {

    const handleChange = (event) => {
        setKeyword(event.target.value);
      }
  return (
    <div className='search-bar-container'>
      <input
        className='search-bar'
        value={keyword}
        placeholder='Search Name, Skills or Comments'
        onChange={handleChange}
      />
    </div>
  )
}
export default SearchBar
