import { createContext, useEffect, useState } from 'react';
import UserService from '../services/user-service';

export const CurrentUserContext = createContext({
  isAuthenticated: null,
  getCurrentUser: () => {},
  login: async (userData) => {},
  logout: async () => {}
})

export const CurrentUserProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        const storagedUser = localStorage.getItem('@App:user');
        if (storagedUser) {
          setCurrentUser(JSON.parse(storagedUser));
        }
      }, []);

      const login = async (userData) => {
        const {success, message, user} = await UserService.login(userData)
        if(success){
            setCurrentUser(user);
            localStorage.setItem('@App:user', JSON.stringify(user));
        }
        return{
            success,
            message
        }
      }

      const logout = async () => {
        const { message } = await UserService.logout(currentUser.token)
        localStorage.clear()
        setCurrentUser(null)
        return {
            status: true,
            message: message
        }
      }

    const getCurrentUser = () => {
        if(!currentUser){
            let response = localStorage.getItem('@App:user');
            if(!response){
                return null
            }
            return JSON.parse(response)
        }
        return currentUser
    }
    return (
    <CurrentUserContext.Provider
        value={{
            isAuthenticated: Boolean(currentUser),
            getCurrentUser,
            login,
            logout
        }}>
            {children}
    </CurrentUserContext.Provider>
    )
}