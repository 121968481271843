import React from 'react'
import '../../assets/styles/consultants-finder-page/filter.styles.scss'

function LevelsFilter ({keyword, setKeyword}) {
    const availableLevels = ['A', 'AC', 'C', 'SRC', 'AM', 'M', 'SRM', 'PE', 'P']

    const handleSelection = (event) => {
      if(keyword.includes(event.target.value)){
          setKeyword([...keyword.filter((value) => {
              return value !== event.target.value
          })])
      }else{
          addToArray(event.target.value)
      }
  }

    const addToArray = (value) => {
        setKeyword([...keyword, value])
    }

  return (
    <div className='filter-container'>
      <div className='filter-title'>Levels</div>
      <table className='filter-buttons'>
        <tbody>
            <tr>
            {availableLevels.map((value, index) => (
                  <td key={index} >
                        <button
                            value={value}
                            onClick={handleSelection}
                            className={keyword.includes(value) ? 'filter-button active' :'filter-button'}
                        >
                            {value}
                        </button>
                  </td>
              ))}
            </tr>
        </tbody>
      </table>
    </div>
  )
}

export default LevelsFilter
