import React, { useState } from 'react'

function ConsultantCardInfoUpdate({ children, consultantUpdateInfo, setConsultantUpdateInfo }) {
  const [showColorPicker, setShowColorPicker] = useState(false)

  const changeColor = (pickedColor) => {
    setConsultantUpdateInfo({ ...consultantUpdateInfo, color: pickedColor })
  }

  return (

    <div className='card-consultant-info-container'>

      <div className='info-header'>
        <div className='consultant-title'>
          <input type='text' placeholder='Name' value={consultantUpdateInfo.name} onChange={(e) => setConsultantUpdateInfo({ ...consultantUpdateInfo, name: e.target.value })} />
        </div>

        <div className='color-selection' onClick={() => { setShowColorPicker(!showColorPicker) }} >
          <input type='color' className='color-input' value={consultantUpdateInfo.color} disabled />
          <div className={(showColorPicker) ? 'color-selection' : 'color-selection picker-hidden'}>
            <div className='color-option green' onClick={() => { changeColor('#00FF00') }}></div>
            <div className='color-option yellow' onClick={() => { changeColor('#FFFF00') }}></div>
            <div className='color-option red' onClick={() => { changeColor('#FF0000') }}></div>
            <div className='color-option blue' onClick={() => { changeColor('#89CFF0') }}></div>
          </div>
        </div>

      </div>

      <div className='consultant-info-columns'>
        <div className='consultant-info left-column'>
          <div className='consultant-attribute'>
            <label>Level:</label>
            <select name='level' value={consultantUpdateInfo.level} onChange={(e) => setConsultantUpdateInfo({ ...consultantUpdateInfo, level: e.target.value })}>
              <option value='' select='selected' disabled>-</option>
              <option value='A'>Analyst</option>
              <option value='AC'>Associate Consultant</option>
              <option value='C'>Consultant</option>
              <option value='SRC'>Senior Consultant</option>
              <option value='AM'>Associate Manager</option>
              <option value='M'>Manager</option>
              <option value='SRM'>Senior Manager</option>
              <option value='PE'>Principal Engineer</option>
              <option value='P'>Partner</option>
            </select>
          </div>
          <div className='consultant-attribute'>
            <label>Location:</label>
            <select name='level' value={consultantUpdateInfo.location} onChange={(e) => setConsultantUpdateInfo({ ...consultantUpdateInfo, location: e.target.value })}>
              <option value='' select='selected' disabled>-</option>
              <option value='NY'>New York</option>
              <option value='SP'>São Paulo</option>
              <option value='SK'>Stockholm</option>
            </select>
          </div>
        </div>
        <div className='consultant-info right-column'>
          <div className='consultant-attribute'>
            <label>Availability:</label>
            <select name='level' value={consultantUpdateInfo.availability} onChange={(e) => setConsultantUpdateInfo({ ...consultantUpdateInfo, availability: e.target.value })}>
              <option value='' select='selected' disabled>-</option>
              <option value='F'>Free</option>
              <option value='PT'>Part time</option>
              <option value='FT'>Fulltime</option>
            </select>
            {consultantUpdateInfo.availability
              ? (
                <>
                  {
                    consultantUpdateInfo.availability === 'F'
                      ? (' since ')
                      : (' until ')
                  }
                  <input id="date" type='date' className='date-filter' value={consultantUpdateInfo.availabilityDate} onChange={(e) => setConsultantUpdateInfo({ ...consultantUpdateInfo, availabilityDate: e.target.value })} />
                </>
              )
              : ('')}
          </div>
        </div>
      </div>

      <div className='consultant-info-columns other-columns'>
        <div className='consultant-info left-column consultant-skills'>
          Skills:
          <textarea
            rows='5' cols='33'
            onChange={(e) => setConsultantUpdateInfo({ ...consultantUpdateInfo, skills: e.target.value })}
          >
            {consultantUpdateInfo.skills}
          </textarea>
        </div>
        <div className='consultant-info right-column consultant-comments'>
          Comments:
          <textarea
            rows='5' cols='33'
            onChange={(e) => setConsultantUpdateInfo({ ...consultantUpdateInfo, comments: e.target.value })}
          >
            {consultantUpdateInfo.comments}
          </textarea>
        </div>
      </div>
      {children}
    </div>

  )
}

export default ConsultantCardInfoUpdate
